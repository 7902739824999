import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/shared/Layout"
import PageHeader from "../components/shared/PageHeader"
import GlobalContent from "../components/global/GlobalContent"
const Global = ({ data }) => {
  return (
    <Layout seoTitle="Global" seoKeywords="Global company, EPC company">
      <PageHeader
        pageTitle="Global"
        pageDescription={data?.markdownRemark?.frontmatter?.header?.description}
      />
      <GlobalContent />
    </Layout>
  )
}

export default Global

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "global" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
  }
`
