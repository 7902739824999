import React from "react"
import { getImage } from "gatsby-plugin-image"
import { Row } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CircleBg from "../../../static/svg/Large-Circle.svg"
import { GradientFooterBg } from "../shared/Footer"
import { Heading24 } from "../../styles/PageStyles"
import { RiExternalLinkLine } from "react-icons/ri"
import { GlobalContainer, GlobalCard } from "./GlobalContent.styled"

const GlobalContent = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "global" } }) {
        frontmatter {
          externalLinks {
            title
            link
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            animationDelay
            hidden
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark

  return (
    <GlobalContainer fluid>
      <Row className="pt-100 pb-100">
        {frontmatter?.externalLinks?.map((external, index) => (
          <>
            {!external?.hidden && (
              <GlobalCard
                key={index}
                lg={4}
                data-aos="fade-down"
                data-aos-delay={external?.animationDelay}
              >
                <a
                  className="content-wrapper"
                  href={external?.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="global-title">
                    <Heading24>
                      {external?.title}&nbsp;
                      <RiExternalLinkLine />
                    </Heading24>
                  </div>
                  <div className="global-image">
                    <GatsbyImage
                      image={getImage(external?.image)}
                      alt={external?.title}
                    />
                  </div>
                </a>
              </GlobalCard>
            )}
          </>
        ))}
      </Row>
      <CircleBg className="circle-bg" />
      <GradientFooterBg />
    </GlobalContainer>
  )
}

export default GlobalContent
